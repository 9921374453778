exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-auth-api-tsx": () => import("./../../../src/templates/authApi.tsx" /* webpackChunkName: "component---src-templates-auth-api-tsx" */),
  "component---src-templates-examples-tsx": () => import("./../../../src/templates/examples.tsx" /* webpackChunkName: "component---src-templates-examples-tsx" */),
  "component---src-templates-index-tsx": () => import("./../../../src/templates/index.tsx" /* webpackChunkName: "component---src-templates-index-tsx" */),
  "component---src-templates-page-template-tsx": () => import("./../../../src/templates/pageTemplate.tsx" /* webpackChunkName: "component---src-templates-page-template-tsx" */),
  "component---src-templates-pages-index-tsx": () => import("./../../../src/templates/pagesIndex.tsx" /* webpackChunkName: "component---src-templates-pages-index-tsx" */),
  "component---src-templates-pagination-example-tsx": () => import("./../../../src/templates/paginationExample.tsx" /* webpackChunkName: "component---src-templates-pagination-example-tsx" */),
  "component---src-templates-post-template-tsx": () => import("./../../../src/templates/postTemplate.tsx" /* webpackChunkName: "component---src-templates-post-template-tsx" */),
  "component---src-templates-posts-index-tsx": () => import("./../../../src/templates/postsIndex.tsx" /* webpackChunkName: "component---src-templates-posts-index-tsx" */)
}

